import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import Layout from "../components/common/Layout";
import Testimonials from "../components/slices/Testimonials";
import Stores from "../components/blocks/Stores";
import Seo from "../components/meta/Seo";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const About = ({ data }) => {
  if (!data) return null;
  const { about, home, settings } = data;
  const testimonialsData = home.data.body.filter((slice) => {
    return slice.slice_type === "testimonials_slider";
  })[0];
  const aboutTextData = home.data.body.filter((slice) => {
    return slice.slice_type === "home_about";
  })[0];
  const { timeline } = about.data;
  const { partners } = about.data;

  return (
    <>
      <Seo page={about} />
      <Layout settings={settings}>
        <Container className="about-page-container">
          <Row className="mb-5">
            <GatsbyImage
              image={about.data.main_image.gatsbyImageData}
              alt={about.data.main_image.alt || "about image"}
              className="rounded"
            />
          </Row>
          <Row className="my-5 px-4 py-5 bg-light rounded">
            <Col md={4}>
              <div>
                <GatsbyImage
                  image={about.data.nasco_manager.gatsbyImageData}
                  alt={about.data.nasco_manager.alt || "manager"}
                  className="rounded mb-3"
                />
                <p className="text-end m-0">
                  <strong>Διευθυντής Nas.Co</strong>
                </p>
                <p className="text-end mb-5">Νάστος Δημήτρης</p>
              </div>
            </Col>
            <Col md={8} className="ps-lg-4">
              <PrismicRichText field={about.data.nasco_manager_text.richText} />
            </Col>
          </Row>
          <Row className="my-5">
            <h1>Καταστήματα Nas.Co</h1>
            <Stores />
          </Row>
          <Row className="bg-light rounded px-4 py-5">
            <h2 className="mb-5">ΣΧΕΤΙΚΑ ΜΕ ΤΗ Nas.Co</h2>
            <div className="d-flex flex-column flex-xl-row">
              <div className="about-page-timeline-text pe-xl-4">
                <PrismicRichText
                  field={aboutTextData.primary.description.richText}
                />
              </div>
              <div className="timeline">
                {timeline.map((time, i) => {
                  return (
                    <div
                      className={`time-box ${i % 2 === 0 ? "left" : "right"}`}
                      key={time.year}
                    >
                      <div className="content rounded">
                        <strong>{time.year}</strong>
                        <p>{time.text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Row>
          <Row className="partners-container">
            <h2 className="partners-title">{about.data.partners_title}</h2>
            <Swiper
              spaceBetween={10}
              slidesPerView={2}
              navigation
              modules={[Pagination, Navigation]}
              breakpoints={{
                576: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                768: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
                992: {
                  slidesPerView: 5,
                  slidesPerGroup: 5,
                },
              }}
            >
              {partners.map((partner, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <SwiperSlide key={i}>
                    <GatsbyImage
                      image={partner.partner_logo.gatsbyImageData}
                      alt={partner.partner_logo.alt || "partner"}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Row>
        </Container>
        <Testimonials slice={testimonialsData} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query AboutPageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      _previewable
      ...prismicSettingsFragment
    }
    about: prismicAbout(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        main_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        nasco_manager {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        nasco_manager_text {
          richText
        }
        partners_title
        partners {
          partner_logo {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
        timeline {
          text
          year
        }
      }
    }
    home: prismicHomepage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        body {
          ... on PrismicHomepageDataBodyHomeAbout {
            id
            slice_type
            slice_label
            primary {
              link_label
              title
              link {
                url
              }
              description {
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyTestimonialsSlider {
            id
            slice_type
            primary {
              title
            }
            items {
              testimonial_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(About);
