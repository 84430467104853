import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";
import { SliceZone } from "@prismicio/react";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import components from "../components/slices/Index";
import { constructActiveDoc } from "../utilities";

const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  return date.toLocaleDateString("el-GR", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
};

const PostPage = ({ data }) => {
  if (!data) return null;
  const { settings, post } = data;
  console.log(settings);

  return (
    <>
      <Seo page={post} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(post)}>
        <Container className="post-page my-5">
          <h1 className="my-5">{post.data.title}</h1>
          <h5 className="mb-3">
            {formatDate(post.first_publication_date)}, by{" "}
            {settings.data.site_title}{" "}
          </h5>
          <div className="d-flex justify-content-center gap-3">
            {post.data.post_main_image.map((image, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  <GatsbyImage
                    image={image.main_image.gatsbyImageData}
                    alt={image.main_image.alt}
                  />
                </div>
              );
            })}
          </div>
          <SliceZone slices={post.data.body} components={components} />
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query postQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    post: prismicPost(lang: { eq: $lang }, id: { eq: $id }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      id
      uid
      tags
      data {
        title
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        post_main_image {
          main_image {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        body {
          ... on PrismicPostDataBodyDownloadFiles {
            id
            slice_type
            slice_label
            items {
              label
              link_to_file {
                url
                type
                target
                id
              }
            }
          }
          ... on PrismicPostDataBodyRichText {
            id
            slice_type
            slice_label
            primary {
              text_description {
                richText
              }
            }
          }
          ... on PrismicPostDataBodyGallery {
            id
            slice_type
            slice_label
            primary {
              button_label
            }
            items {
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicPostDataBodyGallery1 {
            id
            slice_type
            slice_label
            primary {
              button_label
            }
            items {
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicPostDataBodySingleImage {
            id
            slice_type
            slice_label
            primary {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
      last_publication_date
      first_publication_date
    }
  }
`;

export default withPrismicPreview(PostPage);
